import { useRoutes } from 'react-router-dom'

// eslint-disable-next-line import/default, import/namespace, import/no-named-as-default, import/no-named-as-default-member
import { Landing_3 } from 'src/layouts/LandingLayout'

export default function Router() {
const routeElements = useRoutes([
    {
      path: '',
      element: <Landing_3 />
    }
  ])

  return routeElements
}
