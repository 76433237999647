import './landing_3.scss'
export default function Landing_3() {
  return (
    <div className='landing_3'>
      <div className='image_wrapper flex flex-col items-center justify-center'>
        <div className='w-full lg:w-[50%] 2xl:w-[40%]'>
          <section className="part-1">
            <img src="/assets/images/landing_3/mnm-img01.jpg" alt="" />
          </section>
          <section className="part-2">
            <a href="http://mnm001.com" target="_blank" rel="noopener noreferrer">  <img src="/assets/images/landing_3/mnm-img02.jpg" alt="" /></a>
          
          </section>
          <section className="part-3">
            <img src="/assets/images/landing_3/mnm-img03.jpg" alt="" />
          </section>
        </div>
      </div>
    </div>
  )
}
